<template>
  <!-- eslint-disable vue/camelcase -->
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getWidgets"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <label class="d-inline-flex align-items-center ml-3">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getWidgets"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div>
          <router-link
              :to="{
                        name: 'agency.widgets.index',
                      }"
              class="btn btn-outline-dark"
          >Active</router-link>
          <router-link
              :to="{
                        name: 'agency.widgets.archived',
                      }"
              class="btn btn-outline-dark ml-2 active"
          >Archived</router-link>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <template v-if="!loadingWidgets">
              <div class="table-responsive mb-0">
                <b-table
                  :items="widgets"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  data-export-types="['csv']"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(actions)="data">
                    <button type="button"
                        class="btn btn-default btn-sm ml-1"
                            @click="createWidgetGuardianArchived(data)"
                    >
                      <b-spinner v-if="data.item.restoring" class="align-middle mr-2" small></b-spinner>
                      <feather v-else type="refresh-ccw" style="vertical-align: middle" class="mr-2" size="16" />Restore
                    </button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalWidgets }} widgets
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalWidgets"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.status-filter{
  text-transform: capitalize;
}
</style>
<style lang="scss">
iframe#preview-frame{
  width: 100%;
  height: calc(100% - 5px);
}
:root{
  --vs-search-input-color: #000;
  --vs-selected-color: #fff;
  --vs-colors--dark: #000;
  --vs-search-input-bg: transparent;
  --vs-border-width:0;
  --vs-controls-color:#fff;
}
.v-select{
  &.rotator-delay span.vs__selected:after {
    content: 's';
  }
  &.min-rating span.vs__selected:after {
    content: 'Stars';
    padding-left: 2px;
  }
}
.vs__search[readonly]{
  display: none;
}
.v-select:not(.vs--open){
  .vs__search{
    display: none;
  }

}
span.vs__selected{
  right: 0;
}
.vs--unsearchable {
  &.vs--loading .vs__selected, &.vs--open .vs__selected {
    position: relative !important;
  }
}

div#image_overlay_panel{
  z-index: 100000000 !important;
}
.tooltip-inner{
  max-width: 400px;
}
.btn-check {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}
.widget-modal{
  .modal-body{
    padding: 20px;
  }
}
.widget-creator{
  margin: 0;
  padding-top: 0;
  padding-bottom: 10px;
  h3{
    font-size: 16px;
  }
  input, button, .btn{
    font-size: 14px;
  }
}
.pac-container{
  z-index: 100000;
}
.widget-editor{
  padding: 0 !important;
  .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: 0;
    .modal-header{
      background: #4f688f;
      color: #fff;
      padding: 5px 15px;
      border-radius: 0;
      border: 0;
    }
    .modal-body{
      padding: 0;
    }
  }
  .left-nav-side{
    width: 50px;
    font-size: 10px;
    margin-right: 10px;
    text-align: center;
    button{
      margin-bottom: 15px;
      border: 0;
      appearance: none;
      background: none;
      color: #bfbfbf;
      i{
        font-size: 25px;
        line-height: 25px;
      }
      &.active, &:hover{
        color: #fff;
      }
    }
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .nav-sidebar{
    width: calc(100% - 60px);
    > li{
      list-style: none;
      width: 100%;
      > h4{
        margin-top: 0;
        color: #fff;
      }
      > ul{
        > li{
          background: #51688f;
          border-radius: 8px;
          padding: 5px 10px;
          margin-bottom: 10px;
          width: 100%;
          clear: both;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          > span{
            flex: 1;
            flex-wrap: nowrap;
          }
          .vue-js-switch{
            margin-bottom: 0;
          }
          /*.vue-js-switch{
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            margin-bottom: 0;
          }
          .v-select{
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            span.vs__selected{
              position: relative !important;
              + input{
                position: absolute;
                clip: rect(0, 0, 0, 0);
              }
            }
          }*/
        }
      }
    }
    .style-selector{
      label{
        min-height: 120px;
        &:hover{
          color: #fff;
        }
        width: 50%;
        border: 4px solid #51688f;
        &.checked{
          border: 4px solid #00b050;
        }
        img{
          max-width: 100%;
          height: auto;

        }
        span{

        }
      }
    }
    .radius-selector{
      label.btn{
        padding: 3px;
        margin-left: 7px;
        margin-bottom: 0;
        background: #5f7cab;
        &.checked, &:hover{
          background: #00b050;
        }
        img{
          max-height: 18px;
        }
      }
    }
    .v-select ul[role="listbox"]{
      z-index: 1000;
      color: #000;
    }
/*    .v-select:not(.vs--open){
      .vs__search{
        display: none;
      }
    }*/

  }
  .sidebar{
    background-color: #284373;
    color: #fff;
    padding-right: 20px;
    padding-top: 20px;
    min-height: calc(100vh - 52px);
    max-width: 360px;
    width: 100%;
  }
  .editor-content{
    flex: 1;
  }
  --track-width: 45px !important;
  --track-height: 25px !important;
  --track-active-color: #2196F3;
}
</style>
<script>
import 'vue-select/dist/vue-select.css';
import 'vue-slider-component/theme/default.css';
import Vue from "vue";
export default {
  components:{
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'small_date', label: 'Date', sortable: true },
          { key: 'business.business_name', label: 'Business', sortable: true },
          { key: 'actions', label: 'Action', class:'actions', thStyle: { width: "40%" }  },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'archived',
          prospect_status:'all',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      loadingWidgets: false,
    }
  },

  computed: {
    widgets() {
      return this.$store.getters['widgets/all'] || []
    },

    totalWidgets() {
      return this.$store.getters['widgets/total']
    },

    reload() {
      return this.$store.getters['widgets/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },
  watch: {

  },

  created() {
    this.$store.dispatch('widgets/setCurrentPage', 'index')
  },

  mounted() {
    this.getWidgets();
  },

  methods: {
    createWidgetGuardianArchived(widget){
      widget.item.restoring = true;
      this.$store
          .dispatch('widgets/canCreate')
          .then((response) => {
            if(response.success){
              this.restoreWidget(widget);
            }else{
              if(response.data.max_widgets === response.data.widget_count){
                this.restoreWidget(widget);
              }else{
                widget.item.restoring = false;
                response.messages.forEach(message => {
                  Vue.$toast.open({
                    message: message,
                    type: 'error',
                    duration: 15000,
                  })
                });
              }
            }
          })
          .catch((err) => {
            widget.item.restoring = true;
            console.log(err);
          })
    },
    restoreWidget(widget){
      widget.item.restoring = true;
      this.$store
          .dispatch('widgets/restore', widget.item.id)
          .then((res) => {
            this.getWidgets();
            this.loadingWidgets = false
            widget.item.restoring = true;
          })
          .catch(() => {
            this.loadingWidgets = false
            widget.item.restoring = true;
          })
    },
    createWidgetGuardian(){
      this.$store
          .dispatch('widgets/canCreate', { agency_id: this.user.agency.id})
          .then((response) => {
            this.createIsBusy = false;
            if(response.success) {
              this.$emit('widgetCreated');
              this.cid = '';
            }else{
              this.failed = true;
              this.errors = {
                cid:response.message
              };
            }
          })
          .catch((err) => {
            if(err.response.data.errors.hasOwnProperty('cid')){
              this.failed = true;
            }
            this.errors = err.response.data.errors;
            this.createIsBusy = false;
          })
      this.showCreateSABWidgetModal = true;
    },
    csvTitle(){
      return 'review-widget-generator-'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getWidgets()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getWidgets()
    },
    getWidgets() {
      this.loadingWidgets = true

      this.$store
        .dispatch('widgets/getAll', {
          queries: this.datatable.queries,
          page: 'index',
        })
        .then((res) => {
          this.loadingWidgets = false
        })
        .catch(() => {
          this.loadingWidgets = false
        })
    },
    getWidget(widget) {
      this.gettingWidget = true;
      this.$store
          .dispatch('widgets/find', widget.item.id)
          .then((res) => {
            const widget = res;
            // change to string because toggle button does't work with booleans
            widget.review_style.show_read_more_button = widget.review_style.show_read_more_button.toString();
            this.editor.editingWidget = widget;
            this.editor.editingWidget.widget_style = widget.layout_style;
            this.showEditor = true;
          })
          .catch(() => {

          }).finally(() => {this.gettingWidget = false})
    },

    remove(widget) {
      this.showDeleteModal = true
      this.selectedWidget = widget
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedWidget.id
      this.$store
        .dispatch('widgets/archive', this.selectedWidget.id)
        .then(() => {
          this.loadingRemove = null
          this.getWidgets()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
<style scoped>
@media screen and (max-width: 1565px){
  .widget-buttons{
    clear: both;
    margin-bottom: 10px;
    display: block;
  }
}
</style>
